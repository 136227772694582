<template>
    <div class="header-wrapper">
        <div class="header">
    
            <div class="header__logo-container">
                <img src="@/assets/header-logo.svg" class="logo" />
            </div>

            <div v-if="!isMobile" class="header__links">
                <a href="#first-info-block">Продукт</a>
                <a href="#footer">Реквізити</a>
                <a @click="$emit('openContacts')" style="cursor: pointer">Контакти</a>
            </div>
            <div v-else>
                <img @click="openMenu = !openMenu" src="@/assets/menu.svg" class="menu"/>
            </div>

        </div>

        <Transition>
            <div v-if="openMenu" class="mobile-header">
                <div class="header__links-mobile">
                    <a href="#first-info-block">Продукт</a>
                    <a href="#footer">Реквізити</a>
                    <a @click="$emit('openContacts')" style="cursor: pointer">Контакти</a>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'

    export default {
        name: 'HeaderMenu',

        setup() {
            const isMobile = ref(false)
            const openMenu = ref(false)

            const checkIfMobile = () => {
                if (window.innerWidth <= 768) {
                    isMobile.value = true
                } else {
                    isMobile.value = false
                }
            }

            onMounted(() => {
                checkIfMobile()
                window.addEventListener('resize', checkIfMobile)
            })

            return {
                isMobile,
                openMenu
            }
        }
    }
</script>

<style scoped>
    .header-wrapper {
        background-color: #0E1420;
        width: 100%;
    }

    .header {
        width: 80%;
        margin: 0 auto;
        background-color: #0E1420;
        height: 60px;
        display: flex;
        justify-content: space-between;
        z-index: 99;
        position: relative;
    }

    .header__logo-container {
        width: 100px;
        height: 100%;
        display: flex;
        items-align: center;
    }

    .logo {
        width: 50px;
        height: 100%;
    }

    .header__links {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        width: 300px;
    }

    .header__links a {
        color: #C6F100;
        text-decoration: none;
        font-size: 15px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.02em;
        text-align: left;
    }

    .menu {
        width: 30px;
        height: 30px;
        margin-top: 15px;
    }

    .mobile-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 50;
        position: absolute;
        background-color: #0E1420;
        width: 100%;
    }

    .header__links-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .header__links-mobile a {
        color: #C6F100;
        text-decoration: none;
        font-size: 15px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0.02em;
        text-align: left;
    }

    .v-enter-active, .v-leave-active {
        transition: all 1s;
    }

    .v-enter-from, .v-leave-to {
        transform: translateY(-170px);
    }

    .v-enter-to, .v-leave-from {
        transform: translateY(0);
    }

    .header__links-mobile a {
        height: 50px;
    }
    
</style>