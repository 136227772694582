<template>
    <div class="functional-view">
            
            <div class="functional-header">
                Гравець
            </div>

            <div class="functional-container">
                <div class="functional-info">
                    <!-- <img src="@/assets/admin-screen.png" class="functional-image" /> -->
                    <video class="functional-video" autoplay muted loop playsinline>
                        <source src="@/assets/playerskit.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
    
                <div class="functional-list">
                    <ul>
                        <li>мобільний додаток</li>
                        <li>профіль гравця</li>
                        <li>оплата тренувань (за наявності)</li>
                        <li>нагадування</li>
                        <li>контроль відвідувань</li>
                        <li>аналітика</li>
                        <li>мапа локацій тренувань</li>
                        <li>документи</li>
                    </ul>
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        name: 'FunctionalView'
    }
</script>

<style scoped>
    /* мобільні */
    @media (max-width: 767px) {
        .functional-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 30px;
            
        }

        .functional-list ul {
            color: #fff;
            padding: 0;
            font-size: 12px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;

        }

        .functional-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
        }

        .functional-header {
            font-size: 20px;
            font-weight: 900;
            line-height: 40px;
            letter-spacing: 0.02em;
            text-align: center;

            margin-top: 20px;
            color: #C6F100;
            text-transform: uppercase;
        }

        .functional-container {
            
        }

        .functional-view {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
        }

        .functional-video {
            width: 346px;
            height: 210.57px;
        }
    }

    /* таблети */
    @media (min-width: 768px) {
        .functional-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 340px;
            margin-left: 34px;
            
        }

        .functional-list ul {
            color: #fff;
            padding: 0;
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;

        }

        .functional-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
        }

        .functional-header {
            font-size: 20px;
            font-weight: 900;
            line-height: 40px;
            letter-spacing: 0.02em;
            text-align: center;

            margin-top: 20px;
            color: #C6F100;
            text-transform: uppercase;
        }

        .functional-container {
            display: flex;
        }

        .functional-view {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
        }

        .functional-video {
            width: 380px;
            height: 230px;
        }
    }

    /* ноути */
    @media (min-width: 992px) {
        .functional-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 44px;
            width: 409px;
            
        }

        .functional-list ul {
            color: #fff;
            padding: 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: left;

        }

        .functional-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
        }

        .functional-header {
            font-size: 20px;
            font-weight: 900;
            line-height: 40px;
            letter-spacing: 0.02em;
            text-align: center;

            margin-top: 20px;
            color: #C6F100;
            text-transform: uppercase;
        }

        .functional-container {
            display: flex;
            
        }

        .functional-view {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
        }

        .functional-video {
            width: 420px;
            height: 270px;
        }
    }

    /* пекарні */
    @media (min-width: 1200px) {
        .functional-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 549px;
            margin-left: 44px;
            
        }

        .functional-list ul {
            color: #fff;
            padding: 0;
            font-size: 20px;
            font-weight: 500;
            line-height: 38px;
            letter-spacing: 0em;
            text-align: left;

        }

        .functional-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
        }

        .functional-header {
            font-size: 20px;
            font-weight: 900;
            line-height: 40px;
            letter-spacing: 0.02em;
            text-align: center;

            margin-top: 20px;
            color: #C6F100;
            text-transform: uppercase;
        }

        .functional-container {
            display: flex;
            gap: 0;
            justify-content: center;
            width: 100%;
            
        }

        .functional-view {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
        }

        .functional-video {
            width: 493px;
            height: 300px;
        }
    }
</style>