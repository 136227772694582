<template>
    <div class="hero-block">

        <div class="hero-header">
            СИСТЕМА УПРАВЛІННЯ ФУТБОЛЬНИМ КЛУБОМ
        </div>

        <div class="hero-info">
            <img src="@/assets/hero-image.svg" class="hero-image" />
            <div class="divider"></div>
            <div class="hero-list">
                <ul>
                    <li>облік фінансів та ресурсів</li>
                    <li>тренувальний процес</li>
                    <li>аналітика</li>
                    <li>власний клубний дизайн</li>
                    <li>додаток для гравців</li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'HeroBlock'
    }
</script>

<style scoped>
    /* мобільні */
    @media (max-width: 767px) {
        .hero-image {
            width: 130px;
            height: 86.2px;
        }

        .hero-list {
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
        }

        .hero-list ul {
            padding: 0;
        }

        .hero-list ul li {
            margin-bottom: 10px;
            font-size: 12px;
            font-weight: 800;
            line-height: 8px;
            letter-spacing: 0em;
            text-align: left;
        }

        .hero-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: max-content;
            margin-top: 0;
        }

        .divider {
            width: 1px;
            height: 86px;
            margin-left: 18px;
            margin-right: 29px;
            background-color: #0E1420;
        }

        .hero-block {
            background-color: #C6F100;
            width: 100%;
            height: 176px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .hero-header {
            font-size: 13.71px;
            font-weight: 700;
            line-height: 12.24px;
            letter-spacing: 0em;
            text-align: center;
            margin-top: 20px;
        }
    }

    /* таблети */
    @media (min-width: 768px) {
        .hero-image {
            width: 180px;
            height: 140px;
        }

        .hero-list {
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
        }

        .hero-list ul {
            padding: 0;
        }

        .hero-list ul li {
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: 800;
            line-height: 8px;
            letter-spacing: 0em;
            text-align: left;
        }

        .hero-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: max-content;
            margin-top: 10px;
        }

        .divider {
            width: 2px;
            height: 130px;
            margin-left: 33px;
            margin-right: 53px;
            background-color: #0E1420;
        }

        .hero-block {
            background-color: #C6F100;
            width: 100%;
            height: 276px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .hero-header {
            font-size: 18px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            margin-top: 20px;
        }
    }

    /* ноути */
    @media (min-width: 992px) {
        .hero-image {
            width: 200px;
            height: 133px;
        }

        .hero-list {
            font-size: 20px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
        }

        .hero-list ul {
            padding: 0;
        }

        .hero-list ul li {
            margin-bottom: 13px;
            font-size: 16px;
            font-weight: 800;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
        }

        .hero-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: max-content;
            margin-top: 0px;
        }

        .divider {
            width: 2px;
            height: 140px;
            margin-left: 33px;
            margin-right: 53px;
            background-color: #0E1420;
        }

        .hero-block {
            background-color: #C6F100;
            width: 100%;
            height: 276px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .hero-header {
            font-size: 23px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            margin-top: 20px;
        }
    }

    /* пекарні */
    @media (min-width: 1200px) {
        .hero-image {
            width: 261px;
            height: 173px;
        }

        .hero-list {
            font-size: 20px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;
        }

        .hero-list ul {
            padding: 0;
        }

        .hero-list ul li {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 800;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;

        }

        .hero-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: max-content;
            margin-top: 0;
        }

        .divider {
            width: 2px;
            height: 200px;
            margin-left: 53px;
            margin-right: 73px;
            background-color: #0E1420;
        }

        .hero-block {
            background-color: #C6F100;
            width: 100%;
            height: 276px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .hero-header {
            font-size: 28px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            margin-top: 20px;
        }
    }
</style>