<template>
    <div @click="$emit('close')" class="background-blur"></div>
    <div class="contacts-popup">
        <div class="contacts-header">КОНТАКТИ</div>
        <div>
            <div class="contacts-info">
                <div class="contacts-info-header">зателефонуй за номером</div>
                <a href="tel:+380500831436" style="text-decoration: none;"><div class="contacts-info-button">+38 (050) 083 14 36</div></a>
            </div>
            
            <div class="contacts-info">
                <div class="contacts-info-header">надішли повідомлення у Telegram</div>
                <a href="https://t.me/sportkit_support_bot" style="text-decoration: none;">
                    <div class="contacts-info-button">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.0477 3.05293C18.8697 0.707363 2.48648 6.4532 2.50001 8.551C2.51535 10.9299 8.89809 11.6617 10.6672 12.1581C11.7311 12.4565 12.016 12.7625 12.2613 13.8781C13.3723 18.9305 13.9301 21.4435 15.2014 21.4996C17.2278 21.5892 23.1733 5.342 21.0477 3.05293Z" stroke="#0E1420" stroke-width="1.5"/>
                        <path d="M11.5 12.5L15 9" stroke="#0E1420" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </a>
            </div>
            
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContactsPopUp'
    }
</script>

<style scoped>
    /* мобільні */
    @media (max-width: 767px) {
        .contacts-popup {
            display: flex;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #0E1420;
            width: 70%;
            height: 222px;
            padding-top: 1000px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            border: 2px solid #C6F100;
            border-radius: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            z-index: 101;
        }

        .contacts-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            margin-bottom: 20px;
            color: #C6F100;
        }

        .contacts-info-header {
            font-size: 18px;
            line-height: 21px;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            color: #fff;
        }

        .contacts-info-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 40px;
            background: #C6F100;
            border-radius: 10px;
            font-size: 18px;
            line-height: 21px;
            color: #0E1420;
            cursor: pointer;
            font-weight: 700;
        }

        .contacts-header {
            font-size: 21px;
            line-height: 21px;
            margin-top: 20px;
            color: #C6F100;
            margin-bottom: 20px;
        }
    }

    /* таблети */
    @media (min-width: 768px) {
        .contacts-popup {
            display: flex;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #0E1420;
            width: 385px;
            height: 222px;
            padding-top: 1000px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            border: 2px solid #C6F100;
            border-radius: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            z-index: 101;
        }

        .contacts-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            margin-bottom: 20px;
            color: #C6F100;
        }

        .contacts-info-header {
            font-size: 18px;
            line-height: 21px;
            width: 100%;
            text-align: center;
            margin-bottom: 10px;
            color: #fff;
        }

        .contacts-info-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 40px;
            background: #C6F100;
            border-radius: 10px;
            font-size: 18px;
            line-height: 21px;
            color: #0E1420;
            cursor: pointer;
            font-weight: 700;
        }

        .contacts-header {
            font-size: 21px;
            line-height: 21px;
            color: #C6F100;
            margin-bottom: 20px;
        }
    }

    /* ноути */
    @media (min-width: 992px) {
        .contacts-popup {
            display: flex;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #0E1420;
            width: 585px;
            height: 182px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            border: 2px solid #C6F100;
            border-radius: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            z-index: 101;
        }

        .contacts-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            margin-bottom: 20px;
            color: #C6F100;
        }

        .contacts-info-header {
            font-size: 18px;
            line-height: 21px;
            text-align: start;
            margin-bottom: 0;
            width: 302px;
            color: #fff;
        }

        .contacts-info-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 40px;
            background: #C6F100;
            border-radius: 10px;
            font-size: 18px;
            line-height: 21px;
            color: #0E1420;
            cursor: pointer;
            font-weight: 700;
        }

        .contacts-header {
            font-size: 21px;
            line-height: 21px;
            color: #C6F100;
            margin-bottom: 20px;
        }        
    }

    /* пекарні */
    @media (min-width: 1200px) {
        .contacts-popup {
            display: flex;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #0E1420;
            width: 585px;
            height: 241px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            border: 2px solid #C6F100;
            border-radius: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            z-index: 101;
        }

        .contacts-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            margin-bottom: 20px;
            color: #C6F100;
        }

        .contacts-info-header {
            font-size: 18px;
            line-height: 21px;
            width: 302px;
            color: #fff;
        }

        .contacts-info-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 40px;
            background: #C6F100;
            border-radius: 10px;
            font-size: 18px;
            line-height: 21px;
            color: #0E1420;
            cursor: pointer;
            font-weight: 700;
        }

        .contacts-header {
            font-size: 24px;
            line-height: 28px;
            color: #C6F100;
            margin-bottom: 20px;
        }        
    }

    .background-blur {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 100;
    }
</style>