<template>
  <div>
    <ContactsPopUp v-if="openMenu" @close="openMenu = false" />
    <HeaderMenu @openContacts="openMenu = true" />
    <HeroBlock />

    <div class="section-header">загальний опис функціоналу</div>

    <FunctionalView />
    <FunctionalViewTrainer />
    <FunctionalViewStudent />

    <div class="section-header" style="margin-bottom: 45px">вигляд продукту</div>
    <SliderViews />

    <button @click="openMenu = true" class="get-order-button iosRounded">
      Дізнатись більше
    </button>

    <FooterView />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import HeaderMenu from './components/HeaderMenu.vue'
import HeroBlock from './components/HeroBlock.vue'
import FunctionalView from './components/FunctionalView.vue'
import FunctionalViewTrainer from './components/FunctionalViewTrainer.vue'
import FunctionalViewStudent from './components/FunctionalViewStudent.vue'
import SliderViews from './components/SliderViews.vue'
import FooterView from './components/FooterView.vue'
import ContactsPopUp from './components/ContactsPopUp.vue'

export default {
  name: 'App',

  setup() {
    const openMenu = ref(false)


    return {
      openMenu
    }
  },

  components: {
    HeaderMenu,
    HeroBlock,
    FunctionalView,
    FunctionalViewTrainer,
    FunctionalViewStudent,
    SliderViews,
    FooterView,
    ContactsPopUp
  }
}
</script>

<style>
#app {
  background-color: #0E1420;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

body {
  padding: 0;
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0E1420;
}

@font-face {
  font-family: 'Avenir';
  src: url('@/assets/fonts/AvenirLTStd-Book.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('@/assets/fonts/AvenirLTStd-Medium.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('@/assets/fonts/AvenirLTStd-Roman.otf');
  font-weight: 800;
  font-style: normal;
}

/* мобільні */
@media (max-width: 767px) {
  .section-header {
    color: #D9D9D9;
    font-size: 16px;
    font-weight: 400;
    margin-top: 30px;
    letter-spacing: 0em;
    text-align: center;
  }
}

/* таблети */
@media (min-width: 768px) {
  .section-header {
    color: #D9D9D9;
    font-size: 18px;
    font-weight: 400;
    margin-top: 30px;
    letter-spacing: 0em;
    text-align: center;
  }
}

/* ноути */
@media (min-width: 992px) {
  .section-header {
    color: #D9D9D9;
    font-size: 21px;
    font-weight: 400;
    margin-top: 30px;
    letter-spacing: 0em;
    text-align: center;
  }
}

/* пекарні */
@media (min-width: 1200px) {
  .section-header {
    color: #D9D9D9;
    font-size: 24px;
    font-weight: 400;
    margin-top: 30px;
    letter-spacing: 0em;
    text-align: center;
  }

}


.get-order-button {
  /* background-color: #C6F100; */
  background-image: url('@/assets/button.svg');
  background-size: cover;
  background-color: transparent;
  border: none;
  color: #0E1420;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  transition: 0.3s;
  width: 252px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
