<template>
    <div id="footer" class="footer">
        
        <div class="icons">
            <!-- <a href="https://www.facebook.com/">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="#746F72" stroke-width="1.5" stroke-linejoin="round"/>
                <path d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="#746F72" stroke-width="1.5"/>
                <path d="M17.5088 6.5L17.4998 6.5" stroke="#746F72" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
            <a href="https://www.instagram.com/">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.18182 10.3333C5.20406 10.3333 5 10.5252 5 11.4444V13.1111C5 14.0304 5.20406 14.2222 6.18182 14.2222H8.54545V20.8889C8.54545 21.8081 8.74951 22 9.72727 22H12.0909C13.0687 22 13.2727 21.8081 13.2727 20.8889V14.2222H15.9267C16.6683 14.2222 16.8594 14.0867 17.0631 13.4164L17.5696 11.7497C17.9185 10.6014 17.7035 10.3333 16.4332 10.3333H13.2727V7.55556C13.2727 6.94191 13.8018 6.44444 14.4545 6.44444H17.8182C18.7959 6.44444 19 6.25259 19 5.33333V3.11111C19 2.19185 18.7959 2 17.8182 2H14.4545C11.191 2 8.54545 4.48731 8.54545 7.55556V10.3333H6.18182Z" stroke="#746F72" stroke-width="1.5" stroke-linejoin="round"/>
                </svg>
            </a> -->
            <a href="https://t.me/sportkit_support_bot">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.0477 3.05293C18.8697 0.707363 2.48648 6.4532 2.50001 8.551C2.51535 10.9299 8.89809 11.6617 10.6672 12.1581C11.7311 12.4565 12.016 12.7625 12.2613 13.8781C13.3723 18.9305 13.9301 21.4435 15.2014 21.4996C17.2278 21.5892 23.1733 5.342 21.0477 3.05293Z" stroke="#746F72" stroke-width="1.5"/>
                <path d="M11.5 12.5L15 9" stroke="#746F72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
            <a href="https://maps.app.goo.gl/15Adv6cHpoNpg6wm6">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1808_26234)">
                <path d="M20.5137 12C20.5137 16.6944 16.7081 20.5 12.0137 20.5C7.31925 20.5 3.51367 16.6944 3.51367 12C3.51367 7.30558 7.31925 3.5 12.0137 3.5C16.7081 3.5 20.5137 7.30558 20.5137 12Z" stroke="#746F72" stroke-width="1.5"/>
                <path d="M22.5 12H20.5" stroke="#746F72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12H1.5" stroke="#746F72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 1.5L12 3.5" stroke="#746F72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 20.5V22.5" stroke="#746F72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 12C15 13.6569 13.6568 15 12 15C10.3431 15 8.99995 13.6569 8.99995 12C8.99995 10.3431 10.3431 9 12 9C13.6568 9 15 10.3431 15 12Z" stroke="#746F72" stroke-width="1.5"/>
                </g>
                <defs>
                <clipPath id="clip0_1808_26234">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
                </svg>
            </a>
        </div>

        <div class="footer-info">
            <div class="info-container">
                <div>
                    ТОВ “СПОРТ-КІТ”
                    <br><br>
                    ЄДРПОУ 44923493
                </div>
            </div>
            <div class="info-container">
                <div>
                    sportkit.technologies@gmail.com
                    <br><br>
                    +380 50 083 1436
                </div>
            </div>
            <div class="info-container">
                <div>
                    Україна, 88000, Закарпатська область,<br> 
                    Ужгородський район, місто Ужгород,<br>
                    площа Хмельницького Б., будинок 2б, офіс 2
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'FooterView'
    }
</script>

<style scoped>
/* мобільні */
@media (max-width: 767px) {
    .footer {
        border-top: 1px solid #C6F1001A;
    }

    .icons {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    .icons a {
        margin: 0 20px;
    }

    .footer-info {
        display: flex;
        flex-direction: column;
        padding: 50px 50px;
        gap: 50px;
        color: #746F72;
    }

    .footer-info div {
        font-size: 14px;
        line-height: 20px;
    }

    .info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

/* таблети */
@media (min-width: 768px) {
    .footer {
        border-top: 1px solid #C6F1001A;
    }

    .icons {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    .icons a {
        margin: 0 20px;
    }

    .footer-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 50px 0;
        gap: 50px;
        color: #746F72;
    }

    .footer-info div {
        font-size: 14px;
        line-height: 20px;
    }

    .info-container {
        display: flex;
        justify-content: center;
    }
}

/* ноути */
@media (min-width: 992px) {
    .footer {
        border-top: 1px solid #C6F1001A;
    }

    .icons {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    .icons a {
        margin: 0 20px;
    }

    .footer-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 50px 0;
        gap: 50px;
        color: #746F72;
    }

    .footer-info div {
        font-size: 14px;
        line-height: 20px;
    }

    .info-container {
        display: flex;
        justify-content: center;
    }
}

/* пекарні */
@media (min-width: 1200px) {
    .footer {
        border-top: 1px solid #C6F1001A;
    }

    .icons {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    .icons a {
        margin: 0 20px;
    }

    .footer-info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 50px 0;
        gap: 50px;
        color: #746F72;
    }

    .footer-info div {
        font-size: 14px;
        line-height: 20px;
    }

    .info-container {
        display: flex;
        justify-content: center;
    }
}
</style>