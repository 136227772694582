<template>
    <div class="container-slider">
        <carousel :items-to-show="slideToView" :autoplay="2000" :wrap-around="true">
            <slide v-for="slide in 1" :key="'slide_1'">
                <div>
                    <img src="@/assets/slides/slide_1.png" class="slide-image" />
                </div>
            </slide>
            <slide v-for="slide in 1" :key="'slide_2'">
                <div>
                    <img src="@/assets/slides/slide_2.png" class="slide-image" />
                </div>
            </slide>
            <slide v-for="slide in 1" :key="'slide_3'">
                <div>
                    <img src="@/assets/slides/slide_3.png" class="slide-image" />
                </div>
            </slide>
            <slide v-for="slide in 1" :key="'slide_4'">
                <div>
                    <img src="@/assets/slides/slide_4.png" class="slide-image" />
                </div>
            </slide>
            <slide v-for="slide in 1" :key="'slide_5'">
                <div>
                    <img src="@/assets/slides/slide_5.png" class="slide-image" />
                </div>
            </slide>

            <template #addons>
                <pagination />
            </template>
        </carousel>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination } from 'vue3-carousel'

    export default {
        name: 'SliderViews',

        setup() {
            const screenWidth = ref(window.innerWidth)
            const slideToView = ref(5)

            watch(screenWidth, (newWidth) => {
                if (newWidth < 768) {
                    slideToView.value = 1
                } else if (newWidth < 992) {
                    slideToView.value = 3
                } else if (newWidth < 1200) {
                    slideToView.value = 3
                } else {
                    slideToView.value = 5
                }
            })

            onMounted(() => {
                screenWidth.value = window.innerWidth

                if (screenWidth.value < 768) {
                    slideToView.value = 1
                } else if (screenWidth.value < 992) {
                    slideToView.value = 3
                } else if (screenWidth.value < 1200) {
                    slideToView.value = 3
                } else {
                    slideToView.value = 5
                }

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })
            })

            return {
                slideToView
            }
        },

        components: {
            Carousel,
            Slide,
            Pagination,
        },
    }
</script>

<style scoped>
/* мобільні */
@media (max-width: 767px) {
  .section-header {
    color: #D9D9D9;
    font-size: 16px;
    font-weight: 400;
    margin-top: 30px;
    letter-spacing: 0em;
    text-align: center;
  }
  .slide-image {
    width: 345.54px;
  }
}

/* таблети */
@media (min-width: 768px) {
  .section-header {
    color: #D9D9D9;
    font-size: 18px;
    font-weight: 400;
    margin-top: 30px;
    letter-spacing: 0em;
    text-align: center;
  }
  .slide-image {
    width: 31vw;
  }
}

/* ноути */
@media (min-width: 992px) {
  .section-header {
    color: #D9D9D9;
    font-size: 21px;
    font-weight: 400;
    margin-top: 30px;
    letter-spacing: 0em;
    text-align: center;
  }
  .slide-image {
    width: 31vw;
  }
}

/* пекарні */
@media (min-width: 1200px) {
  .section-header {
    color: #D9D9D9;
    font-size: 24px;
    font-weight: 400;
    margin-top: 30px;
    letter-spacing: 0em;
    text-align: center;
  }
  .slide-image {
    width: 18vw;
  }
}
</style>